import React from "react";
import frontendimg from "../../images/front-end.png";
import backendimg from "../../images/backend.png";
import uiImg from "../../images/design.png";
import appImg from "../../images/apps.png";

const Services = () => {
  return (
    <section id="services">
      <div className="container contfadelg:pt-5">
        <div className="text-center">
          <h2 className="text-white font-[800] text-[1.2rem] mb-5 ">
            What do i help?
          </h2>
          <p className="lg:max-w[600px] lg:mx-auto text-white text-[16px] leading-7">
            If you're seeking a patner in all things web and app development,
            you are in the right place!
          </p>
        </div>
        <div className="flex flex-col justify-center sm:py-12">
          <div className="w-full py-3 px-2 sm:max-w-xl sm:mx-auto sm:px-0">
            <div className="relative text-gray-700 antialiased text-sm font-semibold ">
              {/* ========verticle line running through the middle ====== */}
              <div className="hidden absolute w-1 sm:block bg-indigo-300 h-full left-1/2 transform-translate-x-1/2 "></div>
              {/* =======left card ========= */}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">
                  <div className="flex justify-start w-full mx-auto items-center">
                    <div className="w-full sm:w-1/2 sm:pr-8">
                      <div
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        className="bg-[#1b1e29] p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150 "
                      >
                        <h3 className="text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-2xl">
                          Fronted Development
                        </h3>
                        <p className="text-[15px] text-white group-hover:text-white group-hover:font-[500] leading-7">
                          As a frontend developer, I specialize in creating
                          engaging and responsive user interfaces while working
                          with technologies such as
                          react,vue,javascript,wordpress,
                          Tailwind css and
                          bootstrap
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center ">
                    <figure>
                      <img src={frontendimg} alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              {/* =========right card========= */}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">
                  <div className="flex justify-end w-full mx-auto items-center">
                    <div className="w-full sm:w-1/2 sm:pl-8">
                      <div
                        data-aos="fade"
                        data-aos-delay="50"
                        data-aos-duration="1300"
                        className="bg-[#1b1e29] p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150 "
                      >
                        <h3 className="text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-2xl">
                          Backend Development
                        </h3>
                        <p className="text-[15px] text-white group-hover:text-white group-hover:font-[500] leading-7">
                          I'm a versatile backend developer with expertise in
                          multiple technologies, bringing robust solutions to
                          life. I'm proficient in building scalable and
                          efficient backend systems using Express js, MongoDB,
                          Firebase, PHP, and Ruby.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center ">
                    <figure>
                      <img src={backendimg} alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              {/* =======left card ========= */}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">
                  <div className="flex justify-start w-full mx-auto items-center">
                    <div className="w-full sm:w-1/2 sm:pr-8">
                      <div
                        data-aos="fade-right"
                        data-aos-delay="100"
                        data-aos-duration="1400"
                        className="bg-[#1b1e29] p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150 "
                      >
                        <h3 className="text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-2xl">
                          UI/UX Design
                        </h3>
                        <p className="text-[15px] text-white group-hover:text-white group-hover:font-[500] leading-7">
                          UI/UX developer with a passion for crafting visually
                          stunning and user-centric digital experiences. My
                          expertise lies in the intersection of design and
                          development, creating seamless and engaging interfaces
                          that captivate users.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center ">
                    <figure>
                      <img src={uiImg} alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              {/* =========right card========= */}
              <div className="mt-6 sm:mt-0 sm:mb-12 relative">
                <div className="flex items-center flex-col sm:flex-row">
                  <div className="flex justify-end w-full mx-auto items-center">
                    <div className="w-full sm:w-1/2 sm:pl-8">
                      <div
                        data-aos="fade"
                        data-aos-delay="150"
                        data-aos-duration="1500"
                        className="bg-[#1b1e29]  p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150 "
                        
                      >
                        <h3 className="text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-2xl">
                          Apps Development
                        </h3>
                        <p className="text-[15px] text-white group-hover:text-white group-hover:font-[500] leading-7">
                          I'm an enthusiastic Android developer with a passion
                          for crafting innovative solutions. I specialize in
                          creating user-friendly and robust mobile applications
                          using Java and Kotlin within the Android Studio
                          environment.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center ">
                    <figure>
                      <img src={appImg} alt="" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
