import portfolioImg01 from "../images/portfolio-01.jpg";
import portfolioImg02 from "../images/portfolio-02.jpg";
import portfolioImg03 from "../images/portfolio-03.jpg";
import portfolioImg04 from "../images/portfolio-04.jpg";
import portfolioImg05 from "../images/portfolio-05.jpg";
import portfolioImg06 from "../images/portfolio-06.jpg";
import portfolioImg07 from "../images/portfolio-07.jpg";
import portfolioImg08 from "../images/portfolio-08.jpg";
import portfolioImg09 from "../images/portfolio-09.jpg";

const portfolios = [
  {
    id: "01",
    imgUrl: portfolioImg08,
    category: "Web Design",
    title: "Campus Chat Website",
    description:
      "This is a platform for students to interact and share thoughts ",
    technologies: ["Nextjs", "Tailwind css", "Node.js", "MongoDB"],
    siteUrl: "https://campuschat.vercel.app",
  },
  {
    id: "02",
    imgUrl: portfolioImg09,
    category: "Web Design",
    title: "Kidapu Sacco",
    description:
      "This is a sacco group for members management and users to request loans  ",
    technologies: ["WordPress", "Mysql", "php", "PhpmyAdmin"],
    siteUrl: "https://kidapusacco.co.ke/",
  },
  {
    id: "03",
    imgUrl: portfolioImg02,
    category: "Ux",
    title: "Multimedia Sharing Website",
    description:
      "This comprices of a video and picture sharing platform for the motor  community ",
    technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
    siteUrl: "#",
  },
  {
    id: "04",
    imgUrl: portfolioImg01,
    category: "Web Design",
    title: "Finance Forex Website",
    description:
      "This is a site connected to the deriv trading platform hereby one is able to use the bots to trade",
    technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
    siteUrl: "#",
  },
  {
    id: "05",
    imgUrl: portfolioImg03,
    category: "Web Design",
    title: "Landing Page",
    description:
      "This is one of our template designs for web and ui intergration ",
    technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
    siteUrl: "#",
  },

  {
    id: "06",
    imgUrl: portfolioImg06,
    category: "Ux",
    title: "Online Therapy Website",
    description:
      "A user interface design for online therapy website which comprises of an ai chat bot a person can be able to converse with.",
    technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
    siteUrl: "#",
  },
  {
    id: "07",
    imgUrl: portfolioImg07,
    category: "Web Design",
    title: "Appointment Booking Website",
    description:
      "Doctors appointment booking website whereby a patient is able to book an appointment",
    technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
    siteUrl: "#",
  },
  {
    id: "08",
    imgUrl: portfolioImg01,
    category: "Web Design",
    title: "Finance Technology Website",
    description:
      "This site connected to the deriv trading platform hereby one is able to use the bots in this site to trade",
    technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
    siteUrl: "#",
  },
  {
    id: "09",
    imgUrl: portfolioImg02,
    category: "Ux",
    title: "Video Conference Website",
    description:
      "This is a user interface design for online learning and work place video meetings",
    technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
    siteUrl: "#",
  },
  {
    id: "10",
    imgUrl: portfolioImg03,
    category: "Ux",
    title: "File Sharing Website",
    description:
      "This is  connects computers on a network to be able to stream and download movies from each other",
    technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
    siteUrl: "#",
  },
  {
    id: "11",
    imgUrl: portfolioImg04,
    category: "Web Design",
    title: "Landing Page",
    description:
      "This is an information website with on how  to stay safe and methods of prevention againist an epindemic",
    technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
    siteUrl: "#",
  },
  {
    id: "12",
    imgUrl: portfolioImg05,
    category: "Web Design",
    title: "Landing Page",
    description:
      "This is one of our template designs for web and ui intergration with stylish concepts ",
    technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
    siteUrl: "#",
  },
  {
    id: "13",
    imgUrl: portfolioImg06,
    category: "Web Design",
    title: "Online Therapy Website",
    description:
      "A user interface design for online therapy website which comprises of an ai chat bot.",
    technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
    siteUrl: "#",
  },
  {
    id: "14",
    imgUrl: portfolioImg07,
    category: "ux",
    title: "Appointment Booking Website",
    description:
      "Doctors appointment booking website whereby a patient is able to book an appointment.",
    technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
    siteUrl: "#",
  },
];

export default portfolios;
