import { useEffect } from "react";
import Aos from "aos";
import "./App.css";

import Header from "./assets/components/Header/Header";
import Footer from "./assets/components/Footer/Footer";
import Hero from "./assets/components/UI/Hero";
import Services from "./assets/components/UI/Services";
import Portfolio from "./assets/components/UI/portfolio";
import Contact from "./assets/components/UI/Contact";
import { inject } from "@vercel/analytics";
import { SpeedInsights } from "@vercel/speed-insights/react"
function App() {
  useEffect(() => {
    inject();
    Aos.init();
  }, []);
  return (
    <>
      
      <main>
        <Header />
        <Hero />
        <Services />
        <Portfolio />
        <Contact />
        <SpeedInsights/>
      </main>
      <Footer />
    </>
  );
}

export default App;
