import React, { useRef, useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

let lastScrollPos = 0;
const Header = () => {
  const headerRef = useRef(null);
  const menuRef = useRef(null);

  const stickyHeaderFunc = () => {
     const isScrollingUp = window.scrollY < lastScrollPos;
  lastScrollPos = window.scrollY;

  if (isScrollingUp || window.scrollY <= 80) {
    headerRef.current.classList.add("sticky__header");
  } else {
    headerRef.current.classList.remove("sticky__header");
  }
  };
  useEffect(() => {
     Aos.init();
     window.addEventListener("scroll", stickyHeaderFunc);

    return ()=>{ 
      window.removeEventListener("scroll", stickyHeaderFunc);
  };
  }, []);

  const toggleMenu = () => menuRef.current.classList.toggle("show__menu");
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <header
      ref={headerRef}
      className="w-full h-[80px] leading-[80px] flex items-center bg-[#1b1e29]"
    >
      <div className="container">
        <div className="flex items-center justify-between">
          {/* ====logo==== */}
          <div className="flex items-center gap-[10px]">
            <span className="w-[35px] h-[35px] bg-primaryColor text-white text-[18px] font-[500] rounded-full flex items-center justify-center">
              B
            </span>
            <div className="leading-[20px]">
              <h2 className="text-xl text-white font-[700] ">Ben</h2>
              <p className="text-white text-[14px] font-[500]">
                developer
              </p>
            </div>
          </div>
          {/* =======logo end ======== */}
          {/* =======menu start====== */}
          <div className="menu" ref={menuRef} onClick={toggleMenu}>
            <ul className="flex items-center gap-10">
              <li>
                <a
                  className="text-white font-[600]"
                  onClick={() => scrollToSection("about")}
                >
                  About
                </a>
              </li>
              <li>
                <a
                  className="text-white font-[600]"
                  onClick={() => scrollToSection("services")}
                  aria-label="Navigate to services"
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  className="text-white font-[600]"
                  onClick={() => scrollToSection("portfolio")}
                >
                  Portfolio
                </a>
              </li>
              <li>
                <a
                  className="text-white font-[600]"
                  onClick={() => scrollToSection("contact")}
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
          {/* =====menu end====== */}

          {/* =====menu right===== */}
          <div className="flex items-center gap-4">
            <button
              onClick={() => scrollToSection("lets")}
              className="flex items-center gap-2 text-white font-[600] border border-solid border-[#9042f5] py-2 px-4 rounded-[8px] max-h-[40px] hover:bg-primaryColor hover:text-white hover:font-[500] ease-in duration-300"
            >
              <i class="ri-send-plane-line"></i>
              Let's Talk
            </button>
            <span
              onClick={toggleMenu}
              className="text-2xl text-white md:hidden cursor-pointer"
            >
              <i class="ri-menu-line"></i>
            </span>
          </div>
          {/* =====menu end ===== */}
        </div>
      </div>
    </header>
  );
};

export default Header;
