import React from "react";
import eremove from "../../images/remove.png";
import success from "../../images/success.png";

const SuccessMessageModal = ({ registrationStatus, setShowModal }) => {
  // Determine the background color based on registration status
  const statusmsg = registrationStatus.status === 201 ? "Sent!" : "Failed!";
  const statusbtn = registrationStatus.status === 201 ? "Ok" : "Try Again";
  const backgroundColor = registrationStatus.status === 201 ? "bg-[#26ae88]" : "bg-red-600";
  const statusimg = registrationStatus.status === 201 ? {imgUrl: success} :{imgUrl: eremove};


  return (
    <div className=" container sm:px-4 md:px-8 sm:p-4">
      <div className="w-full  h-screen fixed top-0 add_modal left-0 z-10 bg-headingColor bg-opacity-40">
      <div className="max-w-full md:max-w-[600px] w-full absolute top-1/2 left-1/2 z-20 bg-[#dceef0] rounded-[8px] transform -translate-x-1/2 -translate-y-1/2 p-5 sm:p-8">
        
        <div className="mt-5 w-full h-full flex items-center justify-center gap-3 flex-wrap ">
        <figure className="w-[85px] h-[85px] lg:w-[100px] lg:h-[100px]">
          <img src={statusimg.imgUrl} alt="Status" />
        </figure>
        </div>
        <div className="w-full h-full flex items-center mt-5 justify-center">
        <h1 className={`text-white text-2xl max-w-md w-full rounded-[8px] p-5 ${backgroundColor}  text-center font-[700] my-5 `}>
            {statusmsg}
        </h1>
        </div>
        <p className="text-[15px] leading-7 text-center  text-headingColor">
          {registrationStatus.message}
        </p>
        <div className="w-full h-full flex items-center justify-center">
        <button
onClick={() => setShowModal(false)}
className={`mt-5 ${backgroundColor}  text-white hover:text-white  font-bold py-2 px-4 rounded-[5px] cursor-pointer hover:bg-smallTextColor transition duration-300`}
>
          {statusbtn}
        </button>
      </div>
      </div>
      </div>
    </div>
  );
};

export default SuccessMessageModal;
