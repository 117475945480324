import React from "react";
import benimg from "../../images/benlh.png";
import CountUp from "react-countup";

const Hero = () => {
  return (
    <section className=" pt-0 bg-[#12141e]"  id="about">
      <div className="container pt-14">
        <div className="md:flex items-center justify-between sm:flex-col md:flex-row ">
          {/* ======hero left content====== */}
          <div className=" w-full md:basis=1/2">
            <h5
              data-aos="fade-right"
              data-aos-duration="1500"
              className="text-white font-[600] text-[16px] "
            >
              Hello welcome{" "}
            </h5>
            <h1
              data-aos="fade-up"
              data-aos-duration="1500"
              className="text-white text-[800] text-[1.8rem] sm:text[40px] leading-[35px] sm:leading-[46px] mt-5"
            >
              I'm Ben Bii <br />
              Software Engineer
            </h1>
            <div
              data-aos="fade-up"
              data-aos-duration="1800"
              data-aos-delay="200"
              className="flex items-center gap-6 mt-7"
            >
              <a href="#contact">
                <button className="bg-smallTextColor text-white font -[500] flex items-center gap-2 hover:bg-primaryColor ease-in duration-300 py-2 px-4 rounded-[8px] ">
                  <i class="ri-mail-line">Hire me</i>
                </button>
              </a>
              <a
                href="#portfolio"
                className="text-white font-[600] text-[16px] border-b border-solid border-text-white     "
              >
                See portfolio
              </a>
            </div>
            <p
              data-aos="fade-left"
              data-aos-duration="1500"
              className="flex gap-2 text-white font-[500] mt-12 text-[15px] leading-7 sm:pl-14 sm:pr-10 "
            >
              <span>
                <i class="ri-apps-2-line"></i>
              </span>
              Hello! I'm Ben Bii, a versatile developer passionate about
              creating impactful digital solutions. With expertise spanning
              full-stack development, UI/UX design, and proficiency in
              technologies such as React,Nextjs, Node.js, MongoDB, and more, I bring a
              holistic approach to crafting robust and visually appealing
              applications. Committed to continuous learning and collaboration,
              I'm excited about contributing to innovative projects and open to
              new opportunities. Let's connect and explore how we can bring your
              ideas to life!
            </p>
            <div className="flex items-center gap-8 mt-14">
              <span className="text-gray-300 font-[600] text-[15px] ">
               
                Follow me
              </span>
              <span className="w-[35px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center ">
                <a
                  href="https://www.instagram.com/ben_bii_dev"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-300 font-[500] text-[18px]"
                >
                  {" "}
                  <i class="ri-instagram-fill"></i>
                </a>
              </span>
              <span className="w-[35px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center ">
                <a
                  href="https://twitter.com/Tapedoor"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-300 font-[500] text-[18px]"
                >
                  {" "}
                  <i class="ri-twitter-x-fill"></i>
                </a>
              </span>
              <span className="w-[35px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center ">
                <a
                  href="https://github.com/Ben-Kips"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-300 font-[500] text-[18px]"
                >
                  {" "}
                  <i class="ri-github-fill"></i>
                </a>
              </span>
             <span className="w-[35px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center ">
                <a
                  href="https://www.linkedin.com/in/benard-bii"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-300 font-[500] text-[18px]"
                >
                  {" "}
                  <i class="ri-linkedin-box-fill"></i>
                </a>
              </span>
            </div>
          </div>
          {/* ======hero left end====== */}
          {/* ======hero img ======== */}
          <div className="basis-1/3 mt-10 sm:mt-5">
            <figure className="flex items-center justify-center">
              <img src={benimg} alt="imgben" />
            </figure>
          </div>
          {/* ======hero img end====== */}
          {/* ======hero content right====== */}
          <div className="md:basis-1/5 flex justify-between text-center mt-10 flex-wrap gap-3 md:mt-0 md:flex-col md:justify-end md:text-end ">
            <div className="mb-10">
              <h2 className="text-white font-[700] text-[32px] ">
                <CountUp start={0} end={4} duration={6} suffix="+" />
              </h2>
              <h4 className="text-white font-[600] text-[18px] ">
                Years of experience
              </h4>
            </div>
            <div className="mb-10">
              <h2 className="text-white font-[700] text-[32px] ">
                <CountUp start={0} end={99.9} duration={7} suffix="%" />
              </h2>
              <h4 className="text-white font-[600] text-[18px] ">
                Success Rate
              </h4>
            </div>
            <div className="mb-10">
              <h2 className="text-white font-[700] text-[32px] ">
                <CountUp start={0} end={112} duration={14} suffix="+" />
              </h2>
              <h4 className="text-white font-[600] text-[18px] ">
                Happy Clients
              </h4>
            </div>
            <div className="mb-10">
              <h2 className="text-white font-[700] text-[32px] ">
                <CountUp start={0} end={189} duration={15} suffix="+" />
              </h2>
              <h4 className="text-white font-[600] text-[18px] ">
                Completed Projects
              </h4>
            </div>
          </div>
          {/* ======herocontent right end====== */}
        </div>
      </div>
    </section>
  );
};

export default Hero;
