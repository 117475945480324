import axios from "axios";
import React, { useState } from "react";
import SuccessMessageModal from "./SuccessMessageModal";

const Contact = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    subject: "",
    clientMessage: "",
  });
  const [showModal, setShowModal] = useState(false);

  const [registrationStatus, setRegistrationStatus] = useState({ message: "" });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formdata = {
      name: data.name,
      email: data.email,
      subject: data.subject,
      clientMessage: data.clientMessage,
    };

    try {
      const response = await axios.post(
        "https://benbii.online/api/client",
        formdata
      );

      if (response.status === 201) {
        setRegistrationStatus({
          status:response.status,
          message: response.data.message || "Success! Your message was processed successfully.",
        });
        setShowModal(true);
      } else {
        
        setRegistrationStatus({
          status: error.response ? error.response.status : 500,
          message: "Unexpected error" });
         setShowModal(true);
      }
    } catch (error) {
      console.error("Axios Error:", error);

      // Check if the error has a response and use its data, otherwise use a generic error message
      setRegistrationStatus({
        status: error.response ? error.response.status : 500,
        message: error.response ? error.response.data.message : "Unexpected error" }
      );
       setShowModal(true);
    }
  };
  console.log("Registration Status:", registrationStatus.status);

  return (
    <section id="contact" className="pb-16 ">
      <div className="container z-0">
        <h2 className="text-white font-[700] text=[2.5rem] mb-8 ">
          Get in touch
        </h2>
        <div className="md:flex justify-between items-center">
          <div className="w-full md:w-1/2 h-[300px] sm:h-[450px]">
            <iframe
              title="google-maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8173395296994!2d36.82098707418319!3d-1.283462035621396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f10d6678064cf%3A0x956bb6f5e0ab2aac!2sMoi%20Ave%2C%20Nairobi!5e0!3m2!1sen!2ske!4v1696813031215!5m2!1sen!2ske"
              className="border-0 w-full h-full"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div className="w-full mt-8 md:mt-0 md:w-1/2 sm:h-[450px] lg:flex items-center bg-[#1b1e29] px-4 lg:px-8 py-8  ">
            
            <form className="w-full" onSubmit={handleSubmit}>
              <div className="mb-5">
                <input
                  type="text"
                  placeholder=" Enter your name"
                  className="w-full p-3 focus:outline-none rounded-[5px]"
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />
              </div>
              <div className="mb-5">
                <input
                  type="email"
                  placeholder=" Enter your email"
                  className="w-full p-3 focus:outline-none rounded-[5px]"
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                />
              </div>
              <div className="mb-5">
                <input
                  type="text"
                  placeholder=" Subject"
                  className="w-full p-3 focus:outline-none rounded-[5px]"
                  onChange={(e) =>
                    setData({ ...data, subject: e.target.value })
                  }
                />
              </div>
              <div className="mb-5">
                <textarea
                  type="text"
                  placeholder=" Write your message"
                  className="w-full p-3 focus:outline-none rounded-[5px]"
                  onChange={(e) =>
                    setData({ ...data, clientMessage: e.target.value })
                  }
                />
              </div>

              <button className="w-full p-3 focus:outline-none rounded-[5px] bg-smallTextColor text-white hover:bg-headingColor text-center ease-linear duration-150 ">
                Send Message
              </button>
            </form>
            
           <div>
      {/* Your contact form UI */}
      {showModal && 
        <SuccessMessageModal 
          registrationStatus={registrationStatus} 
          setShowModal={setShowModal} 
        />
      }
    </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
