import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <footer className="bg-[#12141e] pt-12">
      {/* ===== footer top======= */}
      <div className="container sm:px-6 md:px-8 sm:p-6 ">
        <div className="sm:flex items-center justify-between md:gap-8">
          <div className="w-full sm:w-1/2">
            <h2 className="text-[26px] leading-10  text-white font-[600] mb-5 md:text-[2rem]">
              Looking for the best experience in your product?
            </h2>

            <div className="w-full sm:w-1/2" id="lets">
              <p className="text-gray-300 leading-7 mt-4 sm:mt-0">
                <i class="ri-mail-send-line"></i> Email: info@benbii.dev
                 <br /><i class="ri-mail-send-line"></i> Email: ben.bii.wrk@gmail.com
                <br /> <i class="ri-phone-line"></i> Phone: +254707641011
              </p>
            </div>
          </div>
          <div className="w-full sm:w-1/2">
            <p className="text-gray-300 leading-7 mt-4 sm:mt-0">
              I'm eager to explore opportunities for collaboration. Let's
              connect and discuss how we can create outstanding user experiences
              together!
            </p>

            <div className="flex items-center gap-4 flex-wrap md:gap-8 mt-10">
              <span className="text-gray-300 font-[600] text-[15px] ">
                Follow Me:
              </span>
              <span className="w-[35px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center ">
                <a
                  href="https://github.com/Ben-Kips"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-300 font-[500] text-[18px] "
                >
                  <i class="ri-github-line"></i>
                </a>
              </span>
              <span className="w-[35px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center ">
                <a
                  href="https://www.linkedin.com/in/benard-bii"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-300 font-[500] text-[18px] "
                >
                  <i class="ri-linkedin-fill"></i>
                </a>
              </span>
              <span className="w-[35px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center ">
                <a
                  href="https://twitter.com/Tapedoor"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-300 font-[500] text-[18px] "
                >
                  <i class="ri-twitter-x-fill"></i>
                </a>
              </span>
              <span className="w-[35px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center ">
                <a
                  href="https://www.instagram.com/ben_bii_dev"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-300 font-[500] text-[18px] "
                >
                  <i class="ri-instagram-fill"></i>
                </a>
              </span>
            </div>
          </div>
        </div>
        <div>
          <ul className="flex items-center text-gray-200 justify-center gap-7 mt-10">
            <li>
              <a
                className="text-gray-400 font-[600]"
                onClick={() => scrollToSection("about")}
              >
                About
              </a>
            </li>
            <li>
              <a
                className="text-gray-400 font-[600]"
                onClick={() => scrollToSection("services")}
                aria-label="Navigate to services"
              >
                Services
              </a>
            </li>
            <li>
              <a
                className="text-gray-400 font-[600]"
                onClick={() => scrollToSection("portfolio")}
              >
                Portfolio
              </a>
            </li>
            <li>
              <a
                className="text-gray-400 font-[600]"
                onClick={() => scrollToSection("contact")}
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
        <div className="flex items-center ease-in duration-300   mt-8 ">
          <a
            href="https://www.digitalocean.com/?refcode=6b9a0587f1f0&utm_campaign=Referral_Invite&utm_medium=Referral_Program&utm_source=badge"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://web-platforms.sfo2.cdn.digitaloceanspaces.com/WWW/Badge%203.svg"
              alt="DigitalOcean Referral Badge"
              className=" md:w-30 md:h-20"
            />
          </a>
        </div>
      </div>
      {/* ===== footer top end======= */}
      {/* ===== footer bottom ======= */}
      <div className="bg-[#1b1e29] py-5 mt-8">
        <div className="container">
          <div className="flex items-center justify-center sm:justify-between  ">
            <div className="hidden sm:block">
              <div className="flex items-center gap-[10px]">
                <span className="w-[35px] h-[35px] rounded-full bg-[#2b2d33] text-white font-[500] text-[18px] flex items-center justify-center ">
                  B
                </span>

                <div className="leading-[20px]">
                  <h2 className="text-gray-200 font-[500] text-[15px] ">Ben</h2>
                  <p className="text-gray-400 text-[14px] font-[500]">
                    developer
                  </p>
                </div>
              </div>
            </div>
            <div className="text-gray-400 text-[14px]">
              Copyright © {year} developed by Ben Bii. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>

      {/* ===== footer bottom end======= */}
    </footer>
  );
};

export default Footer;
